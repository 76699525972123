<template>
  <div style="width: 100%">
    <div class="league_info" v-for="item in $store.state.leagueList"  :class="{'left_league_active':item.checked}" @click="leagueSelector(item)" v-show="item.popular == sportsConst.YES">
      <div class="hot" v-if="item.popular == sportsConst.YES"><img src="../../../../assets/images/left/icon/icon_hot2.png"></div>
      <div class="pic"> <img :src="item.imageUrl" alt=""/></div>
      <div class="name">{{item.leagueName}}</div>
      <div class="count">
        <span>{{item.gcount}}</span>
      </div>
    </div>
  </div>

</template>
<script>
import sportsConst from "@/common/sportsConst";
import {
  RECEIVE_CHECKED_GAME_TYPE,
  RECEIVE_CHECKED_LEAUGE,
  RECEIVE_CHECKED_LEAUGE_DEL,
  RECEIVE_CHECKED_LEAUGE_DELALL
} from "@/store/mutation-types";

export default {
  name: "LeftPopularLeagues",
  data() {
    return {
      sportsConst
    }
  },
  props: {

  },
  methods: {
    leagueSelector(item) {
      //체크상태인 리그 삭제
      item.checked = !item.checked
      if (item.checked) {
        this.$store.commit(RECEIVE_CHECKED_LEAUGE, item)
      } else {
        //체크상태인 리그 삭제
        let index = this.$store.state.selectedLeagueList.findIndex((s) => {
          return s.id === item.id
        })
        if (index !== -1) {
          this.$store.commit(RECEIVE_CHECKED_LEAUGE_DEL, index);
        }
      }
      if (this.$route.name !== 'sports' && this.$route.name !==  'sports_special') {
        if(this.$store.state.displayPostion === sportsConst.GAME_DISPLAYPOSITION_1x2){
          this.$router.push({path: '/front/sports'})
        }
        if(this.$store.state.displayPostion === sportsConst.GAME_DISPLAYPOSITION_SPECIAL){
          this.$router.push({path: '/front/sports_special'})
        }
      }
    },
  },
  watch: {

  }
}
</script>
<style scoped>
  .league_info{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #383838;
    margin: .2rem 0;
    height: 2.8rem;
    color: #cecece;
    border-radius: .5rem;
    cursor: pointer;
    position: relative;
    animation: zoomIn;
    animation-duration: .3s;
  }
  .league_info .pic {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .league_info .pic img {
    width: 2.2rem;
    margin-left: .5rem;
  }
  .league_info .name{
    width: 65%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.2rem;
  }
  .league_info .count{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .count span{
    border-radius: .5rem;
    background-color: #252525;
    width: 4rem;
    height: 2rem;
    font-size: 1rem;
    color: #d3d3d3;
  }
  .league_info .hot{
    position: absolute;
    left: -.0rem;
    top:-.2rem;
  }
  .league_info .hot img{
    width: 2rem;
  }
</style>
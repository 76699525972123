<template>

  <div class="leftwarp" :class="{'activeLeftMenu':activeLeftMenu}">
    <div class="content">
      <left-menu-link-comp></left-menu-link-comp>
      <left-menu-link-casino-slot-comp></left-menu-link-casino-slot-comp>
      <left-menu-title>종목선택</left-menu-title>
      <left-sports-game-type :game-type="sportsConst.GAME_TYPE_ALL"></left-sports-game-type>
      <left-leagues v-show="$store.state.selectedGameType == sportsConst.GAME_TYPE_ALL"></left-leagues>

      <left-sports-game-type :game-type="sportsConst.GAME_TYPE_SOCCER"></left-sports-game-type>
      <left-leagues v-show="$store.state.selectedGameType  == sportsConst.GAME_TYPE_SOCCER"></left-leagues>

      <left-sports-game-type :game-type="sportsConst.GAME_TYPE_BASKETBALL"></left-sports-game-type>
      <left-leagues v-show="$store.state.selectedGameType  == sportsConst.GAME_TYPE_BASKETBALL"></left-leagues>

      <left-sports-game-type :game-type="sportsConst.GAME_TYPE_BASEBALL"></left-sports-game-type>
      <left-leagues v-show="$store.state.selectedGameType  == sportsConst.GAME_TYPE_BASEBALL"></left-leagues>

      <left-sports-game-type :game-type="sportsConst.GAME_TYPE_VOLLEYBALL"></left-sports-game-type>
      <left-leagues v-show="$store.state.selectedGameType  == sportsConst.GAME_TYPE_VOLLEYBALL"></left-leagues>

      <left-sports-game-type :game-type="sportsConst.GAME_TYPE_HOCKEY"></left-sports-game-type>
      <left-leagues v-show="$store.state.selectedGameType  == sportsConst.GAME_TYPE_HOCKEY"></left-leagues>

      <left-sports-game-type :game-type="sportsConst.GAME_TYPE_ESPORTS"></left-sports-game-type>
      <left-leagues v-show="$store.state.selectedGameType  == sportsConst.GAME_TYPE_ESPORTS"></left-leagues>

      <left-sports-game-type :game-type="sportsConst.GAME_TYPE_USA_FOOTBALL"></left-sports-game-type>
      <left-leagues v-show="$store.state.selectedGameType  == sportsConst.GAME_TYPE_USA_FOOTBALL"></left-leagues>
      <left-menu-title>인기리그</left-menu-title>
      <left-popular-leagues></left-popular-leagues>

    </div>
  </div>

</template>

<script>
import BScroll from "better-scroll";
import MouseWheel from '@better-scroll/mouse-wheel'
import ScrollBar from '@better-scroll/scroll-bar'
import LeftMenuLinkComp from "@/views/afront/zero/LeftMenuLinkComp.vue";
import LeftSportsGameType from "@/views/afront/sports/gametype/LeftSportsGameType.vue";
import sportsConst from "@/common/sportsConst";
import LeftLeagues from "@/views/afront/sports/league/LeftLeagues.vue";
import {getLeagues} from "@/network/sportsRequest";
import {mapGetters} from "vuex";
import LeftMenuTitle from "@/views/afront/zero/LeftMenuTitle.vue";
import LeftMenuLinkCasinoSlotComp from "@/views/afront/zero/LeftMenuLinkCasinoSlotImageComp.vue";
import LeftPopularLeagues from "@/views/afront/sports/league/LeftPopularLeagues.vue";

BScroll.use(MouseWheel)
BScroll.use(ScrollBar)
export default {
  name: "Left",
  components: {
    LeftPopularLeagues,
    LeftMenuLinkCasinoSlotComp, LeftMenuTitle, LeftLeagues, LeftSportsGameType, LeftMenuLinkComp},
  data() {
    return {
      activeLeftMenu: false,
      sportsConst,
      leagueList: []
    }
  },
  methods: {

  },
  created() {
    this.$bus.$on('activeLeftMenu', (status) => {
      this.activeLeftMenu = status
    })

  },
  watch: {
    game: {
      handler(newVal) {
      },
      immediate: true,
      deep: false
    },
  }
}
</script>

<style scoped>
.activeLeftMenu {
  opacity: 1;
  transform: translateX(0%) !important;
  transition: 0.3s transform;
}
</style>